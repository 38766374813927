import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import removalIcon from "../../../../../../../assets/shared/removal.svg";
import supplyIcon from "../../../../../../../assets/shared/supply.svg";

import { getEnquiryStatusList } from "../../../../../../utils/methods";

import "./EnquiryTile.scss";

const EnquiryTile = (props) => {
  const { t, id, data } = props;

  const status = getEnquiryStatusList();

  const getQuoteStatusColor = (quoteStatus) => {
    if (quoteStatus === 1) {
      return "enquiry-status in-progress";
    }
    if (quoteStatus === 2) {
      return "enquiry-status quote-sent";
    }
    if (quoteStatus === 3) {
      return "enquiry-status won";
    }
    return "enquiry-status cacnceled";
  };

  const renderEnquiryStatus = (enquiryStatus) => {
    if (enquiryStatus === 1 || enquiryStatus === 2 || enquiryStatus === 3) {
      return (
        <span className={getQuoteStatusColor(enquiryStatus)}>
          {t(status[enquiryStatus])}
        </span>
      );
    }
    return (
      <span className={getQuoteStatusColor(enquiryStatus)}>
        {t("CLIENT_PLATFORM_INQUIRY_STATUS_CANCELED")}
      </span>
    );
  };

  return (
    <div className="enquiry-tile">
      {data &&
        (Object.prototype.hasOwnProperty.call(data, "id") ? (
          <div
            className="enquiry-tile__wrapper"
            title="View Request"
            onClick={() => props.viewRequest(id, data.id)}
            role="button"
            tabIndex="0"
          >
            <img
              className="enquiry-tile__icon"
              src={data.service_id === 1 ? removalIcon : supplyIcon}
              alt="removal-img"
            />
            <div className="enquiry-tile__details-wrapper">
              <div className="enquiry-tile__title-wrapper">
                <div>
                  <h5 className="enquiry-tile__title-id">
                    {data.name !== "Unknown"
                      ? data.name
                      : t("CLIENT_PLATFORM_REQUEST_TYPE_UNKNOWN")}
                  </h5>
                  <span className="enquiry-tile__id">#{data.id}</span>
                </div>

                {renderEnquiryStatus(data.quote_status)}
              </div>
              <p className="enquiry-tile__service-wrapper">
                <span className="enquiry-tile__service-label">
                  {data.service === "EVACUATION" &&
                    t("CLIENT_PLATFORM_SITE_LIST_INQUIRY_SERVICE_REMOVAL")}
                  {data.service === "DELIVERY" &&
                    t("CLIENT_PLATFORM_SITE_LIST_INQUIRY_SERVICE_DELIVERY")}
                  {data.service === "NONE" && t("NONE")}
                  &nbsp;
                </span>
                :
                <span className="enquiry-tile__service">
                  &nbsp;
                  {`${t(data.category_name)} - ${t(data.sub_category_name)}`}
                  &nbsp;
                </span>
              </p>
              <p className="enquiry-tile__quantity-wrapper">
                <span className="enquiry-tile__quantity-label">
                  {t("CLIENT_PLATFORM_SITE_LIST_INQUIRY_QUANTITY")}&nbsp;
                </span>
                :
                <span className="enquiry-tile__quantity">
                  &nbsp;{data.quantity ? data.quantity : 0}
                  &nbsp;{data.unit ? t(data.unit) : " "}
                </span>
              </p>
            </div>
          </div>
        ) : (
          <p className="enquiry-tile__no-data">No Request Available</p>
        ))}
    </div>
  );
};

EnquiryTile.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EnquiryTile);
