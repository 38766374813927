import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropagateLoader from "react-spinners/PropagateLoader";
import * as moment from "moment";
import Skeleton from "react-loading-skeleton";
import ReactGA from "react-ga";

import siteIcon from "../../../assets/shared/site.svg";
import removalIcon from "../../../assets/shared/removal.svg";
import supplyIcon from "../../../assets/shared/supply.svg";
import quoteIcon from "../../../assets/shared/ic-quote.svg";
import SectionHeader from "../SectionHeader";
import AddNew from "../AddNew";
import Documents from "../Documents";

import { prepareShortDateFormat, getEnquiryStatusList } from "../../utils/methods";
import { hidePopUp } from "../../../store/dataStore";

import {
  getRequestMasterDataCallOfOrder,
  getSiteMasterListDataCallOfOrder,
  editCallOffOrder,
} from "../../../modules/CallOffOrderForm/CallOffOrderForm.actionCreators";

import { editTransportEnquiry } from "../../../modules/TransportOnlyForm/TransportOnlyForm.actionCreators";

import {
  getViewData,
  getRequestListingData,
} from "../../../modules/SiteOverview/SiteOverview.actionCreators";

import { editEnquiry } from "../../../modules/InquiryForm/InquiryForm.actionCreators";

import Popup from "../../baseUI/Popup";

import "./RequestViewPopUp.scss";

const RequestViewPopUp = (props) => {
  const {
    data,
    document,
    selectedJobSite,
    hidePopUp,
    quotes,
    t,
    quoteValidatedCount,
    isRequestLoading,
  } = props;
  const { pathname } = window.location;

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
      } else if (countryIdLocal === "2") {
        lng = "fr";
      } else if (countryIdLocal === "3") {
        lng = "pl";
      } else if (countryIdLocal === "6") {
        lng = "de";
      } else if (countryIdLocal === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  const closePopUp = () => {
    props.hidePopUp();
  };

  const onEditEnquiry = (jobsite) => {
    const { related_contact } = props;
    ReactGA.pageview("/createenquiry");
    props.editEnquiry({ jobsite, document, related_contact });
  };

  const onEditTransportEnquiry = () => {
    ReactGA.pageview("/transportonly");
    props.editTransportEnquiry(data);
  };

  /**
   * While creating new call-off-order request pre-fill jobsite and request info.
   */
  const onCreateCallOffOrder = () => {
    const dataLocal = {
      jobsite_id: selectedJobSite.id,
      request: `${data.service} #${data.id} - ${data.name}`,
    };
    ReactGA.pageview("/createcallofforder");
    props.hidePopUp();
    props.getSiteMasterListDataCallOfOrder();
    props.getRequestListData(selectedJobSite.id);
    props.editCallOffOrder({ data: dataLocal });
  };

  const handleJobView = async () => {
    props.hidePopUp();
    await props.getViewData(selectedJobSite.id);
    await props.getRequestListingData({ id: selectedJobSite.id });
  };

  const status = getEnquiryStatusList();

  const getQuoteStatusColor = (quoteStatus) => {
    if (quoteStatus === 1) {
      return "quote-status in-progress";
    }
    if (quoteStatus === 2) {
      return "quote-status quote-sent";
    }
    if (quoteStatus === 3) {
      return "quote-status won";
    }
    return "quote-status canceled";
  };

  const renderEnquiryStatus = (enquiryStatus) => {
    if (enquiryStatus === 1 || enquiryStatus === 2 || enquiryStatus === 3) {
      return (
        <span className={getQuoteStatusColor(enquiryStatus)}>
          {t(status[enquiryStatus])}
        </span>
      );
    }
    return (
      <span className={getQuoteStatusColor(enquiryStatus)}>
        {t("CLIENT_PLATFORM_INQUIRY_STATUS_CANCELED")}
      </span>
    );
  };

  const header = () => {
    return (
      <header>
        {isRequestLoading && (
          <>
            <div className="request-loader-wrapper">
              <Skeleton width={50} height={50} />
              <div className="request-loader-service">
                <Skeleton width={300} />
                <Skeleton width={200} />
              </div>
            </div>
          </>
        )}
        {!isRequestLoading && (
          <>
            <div className="media data-list-item border-0 p-0">
              <div className="media-img p-0 rounded-0">
                <img
                  className="m-0"
                  src={data.service_id === 1 ? removalIcon : supplyIcon}
                  alt="service-img"
                />
              </div>
              <div className="media-body">
                <h5
                  className="m-0 gray-darker-text"
                  style={{ paddingRight: "10px" }}
                >
                  {data.name !== "Unknown"
                    ? data.name
                    : t("CLIENT_PLATFORM_REQUEST_TYPE_UNKNOWN")}
                  <span className="gray-text id pl-2">{`#${data.id}`}</span>
                  {data.quote_status && renderEnquiryStatus(data.quote_status)}
                </h5>
                <p className="service-info mb-0">
                  {t(data.service)} :
                  <span className="gray-darker-text pl-1">
                    {`${t(data.category_name)} - ${t(data.sub_category_name)}`}
                  </span>
                </p>
              </div>
            </div>
            {parseInt(localStorage.getItem("userId"), 10) ===
              parseInt(selectedJobSite.user_id, 10) && (
              <NavLink
                to={data.sub_category_id !== 9 ? "/createenquiry" : "/transportonly"}
                className="fontLato14"
                onClick={() => {
                  data.sub_category_id !== 9
                    ? onEditEnquiry(selectedJobSite)
                    : onEditTransportEnquiry();
                }}
              >
                <button
                  className="btn btn-primary btn-sm edit-enquiry-btn btn-transparent position-absolute"
                  disabled={data.state >= 2}
                  type="button"
                >
                  <i className="fa fa-pencil pr-2" aria-hidden="true" />
                  {t("CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_EDIT_BUTTON")}
                </button>
                <i className="fa fa-pencil pr-2 edit-enquiry" aria-hidden="true" />
              </NavLink>
            )}
          </>
        )}
      </header>
    );
  };

  const renderDuration = (duration) => {
    const durationOptions = {
      "1_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_MONTH"
      ),
      "2_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_MONTHS"
      ),
      "3_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_3_MONTHS"
      ),
      "6_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_6_MONTHS"
      ),
      "1_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_YEAR"
      ),
      "2_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_YEARS"
      ),
      MORE: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_MORE"),
    };

    return durationOptions[duration] ? durationOptions[duration] : "";
  };

  const renderPrice = (price, unit, euroCurrency, ukCurrency) => {
    if (euroCurrency) {
      return `${price} ${euroCurrency}/${t(unit)}`;
    }
    return `${ukCurrency}/${t(unit)} ${price}`;
  };
  const renderQuotes = () => {
    return (
      <ul className="quotes-list">
        {quotes.map((quote, index) => {
          return (
            <li key={index} className="quotes-list-item">
              <img src={quoteIcon} className="quote-icon" alt="quote icon" />
              <div className="quote-info">
                <p className="quotes-service-name">{`${quote[0].service} : `}</p>
                <p className="quotes-price">
                  {quote[0].euroCurrency &&
                    `${quote[0].price} ${quote[0].euroCurrency}`}
                  {quote[0].ukCurrency && `${quote[0].ukCurrency} ${quote[0].price}`}
                  {` / ${t(quote[0].unit)}`}
                </p>
              </div>
              {quote[1] && (
                <div className="quote-info">
                  <p className="quotes-service-name">{`${quote[1].service} : `}</p>
                  <p className="quotes-price">
                    {quote[1].euroCurrency &&
                      `${quote[1].price} ${quote[1].euroCurrency}`}
                    {quote[1].ukCurrency &&
                      `${quote[1].ukCurrency} ${quote[1].price}`}
                    {` / ${t(quote[1].unit)}`}
                  </p>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const content = () => {
    return (
      <div className="row request-view-content">
        {isRequestLoading && (
          <div className="col-12 loader-container">
            <div className="loader-wrapper">
              <PropagateLoader size={20} color="#4a90e2" />
            </div>
          </div>
        )}
        {!isRequestLoading && (
          <>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div className="related-job-info h-100 p-4">
                <div className="info-details">
                  <h4 className="info-title">
                    {t("CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_SITE_TITLE")}
                  </h4>
                  <NavLink
                    to="/viewsite"
                    onClick={() => {
                      pathname !== "/viewsite" ? handleJobView() : hidePopUp();
                    }}
                  >
                    <div className="card border-top-0 my-2 card-hover">
                      <div className="media data-list-item border-0">
                        <div className="media-img p-0 rounded-0">
                          <img className="m-0" src={siteIcon} alt="site-img" />
                        </div>

                        <div className="media-body">
                          <h5 className="m-0">
                            {selectedJobSite.company_name
                              ? `${selectedJobSite.company_name} - ${selectedJobSite.name}`
                              : selectedJobSite.name}
                          </h5>
                          <p className="jobsite-id">{`#${selectedJobSite.id}`}</p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="info-details">
                  <h4 className="info-title mt-4">
                    {t("CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_QUOTE_TITLE")}
                  </h4>
                  {quotes.length === 0 && (
                    <p className="fontLato14">
                      {t(
                        "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_QUOTE_NO_QUOTE_AVAILABLE"
                      )}
                    </p>
                  )}
                  {quotes.length > 0 && renderQuotes()}
                </div>
                {selectedJobSite.status_id === 1 &&
                  moment(selectedJobSite.end_date).isSameOrAfter(new Date()) &&
                  quoteValidatedCount > 0 && (
                    <div>
                      <SectionHeader
                        title={t(
                          "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_CALL_OFF_ORDER_TITLE"
                        )}
                        link="/sitesandenquiries"
                        viewAllTitle="My call-off orders"
                        isViewAllVisible={false}
                      />
                      <AddNew
                        link="/createcallofforder"
                        name={t(
                          "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_CREATE_CALL_OFF_ORDER_BUTTON"
                        )}
                        onAdd={() => {
                          onCreateCallOffOrder();
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 model-pad">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 request-container">
                  <div className="request-view-details py-4">
                    <div className="request-detail-info">
                      <h4 className="mb-3">
                        {t(
                          "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_TITLE"
                        )}
                      </h4>
                      <div className="request-detail-list">
                        <label>
                          {`${t(
                            "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_SERVICE"
                          )} : `}
                        </label>
                        <span>{t(data.service)}</span>
                      </div>
                      <div className="request-detail-list">
                        <label>
                          {`${t(
                            "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_CATEGORY"
                          )} : `}
                        </label>
                        <span>
                          {`${t(data.category_name)} - ${t(data.sub_category_name)}`}
                        </span>
                      </div>
                      <div className="request-detail-list mb-2">
                        <label>
                          {`${t(
                            "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_PRODUCT"
                          )} : `}
                        </label>
                        {data.product.length > 0 && data.product[0].product?.name && (
                          <span key={data.product[0].product_id} className="mb-1">
                            {data.product[0].product.name}
                          </span>
                        )}
                      </div>
                      <div className="request-detail-list">
                        <label>
                          {`${t(
                            "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_DATES"
                          )} : `}
                        </label>
                        <span className={data.selected_days ? "mb-0" : "pr-1"}>
                          {data.selected_days &&
                            `${prepareShortDateFormat(data.start_date)} -
                              ${prepareShortDateFormat(data.end_date)}`}

                          {!data.selected_days &&
                            `${prepareShortDateFormat(
                              data.start_date
                            )} - ${renderDuration(data.duration)}`}
                        </span>
                        {data.selected_days && (
                          <span>
                            {data.selected_days &&
                              data.selected_days
                                .split(",")
                                .map((day) => `${t(day.toUpperCase())} `)}
                          </span>
                        )}
                      </div>
                      <div className="request-detail-list">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>
                                <label>
                                  {t(
                                    "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_QUANTITY"
                                  )}
                                </label>
                              </th>
                              {((data.service_id === 1 && data.category_id === 2) ||
                                (data.service_id === 2 &&
                                  data.category_id === 1)) && (
                                <th>
                                  <label>
                                    {t(
                                      "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_PRICE"
                                    )}
                                  </label>
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span>{`${data.quantity} ${t(data.unit)}`}</span>
                              </td>
                              {((data.service_id === 1 && data.category_id === 2) ||
                                (data.service_id === 2 &&
                                  data.category_id === 1)) && (
                                <td>
                                  <span>
                                    {data.price
                                      ? renderPrice(
                                          data.price,
                                          data.price_unit,
                                          data.euroCurrency,
                                          data.ukCurrency
                                        )
                                      : "-"}
                                  </span>
                                </td>
                              )}
                            </tr>
                          </tbody>
                          <thead>
                            <tr>
                              <th>
                                <label>
                                  {t(
                                    "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_TRANSPORT"
                                  )}
                                </label>
                              </th>

                              <th>
                                <label>
                                  {t(
                                    "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_REQUEST_INFORMATION_LOADS_PER_DAY"
                                  )}
                                </label>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-top">
                                <ul className="transport-list">
                                  <li className="transport-main">
                                    {data.transport.map((x, i) => {
                                      if (i < 3) {
                                        return (
                                          <span
                                            key={x.transport_id}
                                            className={
                                              i === 2 && data.transport.length > 3
                                                ? "transport-item show-dot m-0"
                                                : "transport-item m-0"
                                            }
                                          >
                                            {x.transport.name}
                                          </span>
                                        );
                                      }
                                      return "";
                                    })}
                                  </li>
                                  <li className="transport-hover">
                                    {data.transport.map((x, i) => {
                                      if (i > 3) {
                                        return (
                                          <span
                                            key={x.transport_id}
                                            className="transport-item m-0"
                                          >
                                            {x.transport.name}
                                          </span>
                                        );
                                      }
                                      return "";
                                    })}
                                  </li>
                                </ul>
                              </td>

                              <td className="align-top">
                                <span>
                                  {data.truck_per_day ? data.truck_per_day : "-"}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <div className="request-view-details py-4 pr-4">
                    <div className="request-detail-info">
                      <Documents
                        documents={document}
                        title={t(
                          "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_DOCUMENTS_TITLE"
                        )}
                        noDocTitle={t(
                          "CLIENT_PLATFORM_REQUEST_VIEW_POP_UP_DOCUMENTS_NO_DOCUMENTS_AVAILABLE"
                        )}
                        canDownload={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <Popup
        modalParentClass="modal-parent request-view-popup"
        modalClass="modal-box"
        title={header()}
        name="request"
        content={content()}
        id="requestViewPopup"
        onClose={closePopUp}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.dataStore.requestCreationData,
    quotes: state.dataStore.requestViewQuote,
    related_contact: state.dataStore.relatedContact.enquiry.related_contact,
    document: state.dataStore.relatedContact.enquiry.document,
    selectedJobSite: state.dataStore.selectedJobSiteForRequest,
    quoteValidatedCount: state.dataStore.quoteValidatedCount,
    isRequestLoading: state.dataStore.isRequestViewPopupLoading,
  };
};

const mapDispatchToProps = {
  hidePopUp: () => hidePopUp(),
  editEnquiry: (payload) => editEnquiry(payload),
  editTransportEnquiry: (payload) => editTransportEnquiry(payload),
  editCallOffOrder: (payload) => editCallOffOrder(payload),
  getViewData: (payload) => getViewData(payload),
  getRequestListingData: (payload) => getRequestListingData(payload),
  getRequestListData: (payload) => getRequestMasterDataCallOfOrder(payload),
  getSiteMasterListDataCallOfOrder: () => getSiteMasterListDataCallOfOrder(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RequestViewPopUp));
